import styles from "../styles.js";
import "../index.css";
import { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { MdSettings } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
const Toolarea = ({ theme, advancedOptions, setAdvancedOptions }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(16);
  const wordCount = text.trim().split(/\s+/).filter(Boolean).length;
  const charCount = text.length;
  const [findText, setFindText] = useState("");
  const [replaceText, setReplaceText] = useState("");
  const [newtext, setNewText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [matchCase, setMatchCase] = useState(false);
  const [matchWholeWord, setMatchWholeWord] = useState(false);
  const [replaceCount, setReplaceCount] = useState(0);
  const [showReplacedText, setShowReplacedText] = useState(false);
  const [containHTML, setContainHTML] = useState(false);
  const [outputText, setOutputText] = useState("");

  const handleCopyToClipboard = () => {
    if (newtext.length !== 0) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  };

  const handleReset = () => {
    setFindText("");
    setReplaceText("");
    setText("");
    setNewText("");
    setMatchCase(false);
    setMatchWholeWord(false);
    setReplaceCount(0);
    setShowReplacedText(false)
  };
  const containsHTMLWithLineBreak = /<|>|&|&lt;|&gt;|\n/;

  const handleTextareaChange = (e) => {
    setText(e.target.value);
  };

  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  const handleFindTextChange = (e) => {
    setFindText(e.target.value);
  };

  const handleReplaceTextChange = (e) => {
    setReplaceText(e.target.value);
  };

  const defaultFontSize = 16;

  const handleResetFontSize = () => {
    setFontSize(defaultFontSize);
  };

  const handleMatchCaseChange = () => {
    setMatchCase((pre) => !pre);
  };

  const handleMatchWholeWordChange = () => {
    setMatchWholeWord((pre) => !pre);
  };

  function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const handleReplace = () => {
    if (findText === "") {
      setNewText(text);
      setOutputText(text);
    } else {
      const flags = matchCase ? "g" : "gi";
      const escapedFindText = escapeRegExp(findText);
      const regex = matchWholeWord
        ? new RegExp(`\\b${escapedFindText}\\b`, flags)
        : new RegExp(escapedFindText, flags);
      const updatedText = text.replace(regex, (match) => {
        return replaceText;
      });
      const replacedCount = (text.match(regex) || []).length;
      setNewText(updatedText);
      setReplaceCount(replacedCount);
      if (showReplacedText) {
        const updatedOutputText = text.replace(regex, (match) => {
          return `<span class="highlighted">${match}</span>`;
        });
        setOutputText(updatedOutputText);
      }
    }
  };

  useEffect(() => {
    handleReplace();
  }, [text, findText, replaceText, matchCase, matchWholeWord, showReplacedText, containHTML]);

  useEffect(() => {
    if (containsHTMLWithLineBreak.test(replaceText) || containsHTMLWithLineBreak.test(text)) {
      setContainHTML(true);
      setShowReplacedText(false);
    } else {
      setContainHTML(false);
    }
  }, [text, replaceText]);


  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      if (container.scrollHeight > container.clientHeight) {
        container.style.overflowY = 'scroll';
      } else {
        container.style.overflowY = 'hidden';
      }
    }
  }, [newtext]);


  return (
    <>
      <section>
        <div className={`flex items-end flex-col xl:px-0 sm:px-16 px-6 mb-3`}>
          <input aria-label="Font Size" type='range' id="fontSizeSlider" min='12' max='28' value={fontSize} className={`mt-2 common-color cursor-pointer`} onChange={handleFontSizeChange} />
          <button
            type='button'
            onClick={handleResetFontSize}
            className='mt-2 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br  shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-normal rounded-lg text-sm px-2 py-2 text-center mr-1 mb-1 dark:bg-gradient-to-r dark:from-cyan-400 dark:via-cyan-500 dark:to-cyan-600 dark:hover:bg-gradient-to-br dark:text-white'
          >
            {t("tool.Reset_Font_Size")}
          </button>
        </div>
      </section>
      <section id='toolbar' className={`ol`}>
        <div className={`flex items-start flex-col xl:px-0 sm:px-16 px-6 h-3/5 w-100`}>
          <label htmlFor='message' className={`block mb-3 ${styles.paragraph} text-primary dark:text-gray-300 font-poppins font-normal`}>
            {t("tool.Input_data")}
          </label>
          <textarea
            onChange={handleTextareaChange}
            spellCheck="false"
            value={text}
            style={{
              fontSize: `${fontSize}px`,
              lineHeight: `${fontSize}>=20 ? '2rem':'1'`,
              minHeight: '380px',
              maxHeight: "381px",
            }}
            placeholder={t("tool.Paste_your_text_here")}
            id='message'
            rows='15'
            cols='100'
            className='resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          ></textarea>
          <p className='block mt-2 font-poppins text-sm text-gray-900 dark:text-white mb-5'>
            {t("tool.Words_Count")} <span className='font-poppins font-medium text-base'>{wordCount}</span> &nbsp; {t("tool.Characters_Count")}{" "}
            <span className='font-poppins text-base font-medium'>{charCount}</span>
          </p>
        </div>
        <div className={`flex items-start flex-col xl:px-0 sm:px-16 px-6 h-3/5 w-100`}>
          <label htmlFor='contentEditableMessage' className={`block mb-3 ${styles.paragraph} text-primary dark:text-gray-300 font-poppins font-normal`}>
            {t("tool.Output_data")}
          </label>
          {containHTML ? <div
            role="textbox"
            aria-multiline="true"
            tabindex="0"
            id="contentEditableMessage"
            aria-label="Ouput"
            ref={containerRef}
            contentEditable={false}
            aria-readonly
            spellCheck="false"
            style={{
              fontSize: `${fontSize}px`,
              whiteSpace: 'pre-wrap',
              minHeight: '380px',
              maxHeight: "381px",
              padding: '10px',
              width: "100%",
              overflowY: "scroll",
              cursor: "text",
              outline: "none"
            }}
            className='ctextbox resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          >{showReplacedText ? outputText : newtext}
          </div>
            : <div
              role="textbox"
              aria-multiline="true"
              contentEditable={false}
              aria-readonly
              aria-label="Ouput"
              ref={containerRef}
              spellCheck="false"
              style={{
                fontSize: `${fontSize}px`,
              }}
              tabIndex="0"
              id='contentEditableMessage'
              dangerouslySetInnerHTML={{
                __html: showReplacedText
                  ? outputText
                  : newtext,
              }}
              className='ctextbox resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            >
            </div>}

          {text.length > 0 && findText.length > 0 && replaceCount > 0 && replaceText.length < 30 && findText.length < 30 &&
            <div className={`flex flex-row mt-2 w-full justify-start items-center font-poppins text-sm text-gray-900 dark:text-white `}>
              <span className="font-poppins"><span className='text-base underline'>{(replaceCount > 0 && findText.trim() === "") ? '\u00A0' : findText}</span>{" "}({findText.length}){" "}{t("tool.Counter_Text")} <span className="font-medium">{replaceCount}</span> {t("tool.Times_With")}{" "}<span className='font-poppins text-base underline'>
                {(replaceCount > 0 && replaceText.trim() === '') ? '\u00A0' : replaceText}
              </span>{" "}({replaceText.length})</span>
            </div>
          }
          <div className={`flex flex-row gap-5 w-full justify-between items-center flex-wrap mt-3 mb-3`}>
            {!containHTML && replaceCount > 0 && <div className="flex items-center gap-1.5">
              <input
                type="checkbox"
                id="showReplacedTextCheckbox"
                checked={showReplacedText}
                onChange={() => setShowReplacedText((prevState) => !prevState)}
                className="w-4 h-4 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="showReplacedTextCheckbox">
                <span className="font-poppins text-sm text-gray-900 dark:text-white ">
                  {t("tool.Verify")}
                </span>
              </label>
            </div>
            }
            <div>
              <CopyToClipboard text={newtext} onCopy={handleCopyToClipboard}>
                <button
                  type='button'
                  className='mt-2 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-normal rounded-lg text-sm px-2 py-2 text-center mr-1 mb-1 dark:bg-gradient-to-r dark:from-cyan-400 dark:via-cyan-500 dark:to-cyan-600 dark:hover-bg-gradient-to-br dark:text-white'>
                  {isCopied ? t("tool.Copied") : t("tool.Copy_to_Clipboard")}
                </button>
              </CopyToClipboard>
            </div>
            <div>
              <button
                type='button'
                onClick={handleReset}
                className=' mt-2 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-2 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
              >
                {t("tool.Reset")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="hidden sm:block">
        <div
          className={`flex flex-col gap-2 justify-evenly xl:justify-around w-screen xl:px-0 sm:px-16 px-6 mt-5 fixed bottom-0 items-center py-3 z-[5] ${theme === "light" ? "bg-slate-500 " : theme === "dark" ? "bg-discount-gradient " : "bg-slate-500 "}`}
        >
          <div
            onClick={() => setAdvancedOptions((pre) => !pre)}
            className={`flex flex-row items-center ${theme === "light" ? "bg-slate-500 " : theme === "dark" ? "bg-discount-gradient " : "bg-slate-500"} settings-container p-2 pr-3 pl-3 rounded `}
          >
            {(!advancedOptions) ? (
              <span className={` ${styles.flexCenter} gap-2 font-poppins cursor-pointer text-[14px] dark:text-white text-slate-200`}>
                <MdSettings className={`${styles.flexCenter} hover:rotate-45 w-[20px] h-[20px] object-contain dark:text-sky-400 text-slate-100`} alt='settings' />
                {t("tool.Options")}
              </span>
            ) : (
                <AiOutlineClose className={`${styles.flexCenter} w-[20px] h-[20px] object-contain dark:text-sky-400 text-slate-200 transition ease-in-out duration-300 hover:scale-110`} alt='settings' />
            )}
          </div>

          {advancedOptions && <div
            className={`flex flex-row items-center gap-5 font-poppins cursor-pointer text-[14px] dark:text-white text-slate-200 mb-1`}
          >
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="matchCaseCheckbox"
                checked={matchCase}
                onChange={handleMatchCaseChange}
                className="w-4 h-4 bg-orange-500 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="matchCaseCheckbox">{t("tool.Match_Case")}</label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="matchWholeWordCheckbox"
                checked={matchWholeWord}
                onChange={handleMatchWholeWordChange}
                className="w-4 h-4 bg-orange-500 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="matchWholeWordCheckbox">{t("tool.Match_Whole_Word")}</label>
            </div>
          </div>
          }
          <div className="flex flex-col sm:flex-row gap-5 justify-evenly xl:justify-around w-screen xl:px-0 sm:px-16 px-6">
            <div>
              <textarea
                rows='1'
                value={findText}
                onChange={handleFindTextChange}
                placeholder={t("tool.Text_to_Find")}
                cols='50'
                spellCheck="false"
                className='cursor-text resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              ></textarea>
            </div>
            <div>
              <textarea
                placeholder={t("tool.Text_to_Replace")}
                rows='1'
                spellCheck="false"
                value={replaceText}
                onChange={handleReplaceTextChange}
                cols='50'
                className=' cursor-text resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              ></textarea>
            </div>
          </div>
        </div>
      </section>
      <section className="block sm:hidden">
        <div
          className={`flex flex-col gap-2 justify-evenly xl:justify-around w-screen xl:px-0 sm:px-16 px-6 mt-5 fixed bottom-0 items-center py-3 z-[5] ${theme === "light" ? "bg-slate-500 " : theme === "dark" ? "bg-discount-gradient " : "bg-slate-500 "}`}
        >
          {!advancedOptions && 
            <span onClick={() => setAdvancedOptions(true)} className={` ${styles.flexCenter} gap-2 font-poppins cursor-pointer text-[14px] dark:text-white text-slate-200 transition ease-in-out duration-300 `}>
            <MdSettings className={`${styles.flexCenter} hover:rotate-45 w-[20px] h-[20px] object-contain dark:text-sky-400 text-slate-100`} alt='settings' />
            {t("tool.Options")}
          </span>}

          {advancedOptions && <div onClick={() => setAdvancedOptions(false)}
            className={`flex flex-row items-center ${theme === "light" ? "bg-slate-500 " : theme === "dark" ? "bg-discount-gradient " : "bg-slate-500"} settings-container-mb p-2 pr-3 pl-3 rounded `}
          >
            <AiOutlineClose className={`${styles.flexCenter} w-[20px] h-[20px] object-contain dark:text-sky-400 text-slate-200 transition ease-in-out duration-300 hover:scale-110`} alt='settings' />
          </div>}
          {advancedOptions &&
            <div
              className={`flex flex-row items-center gap-5 font-poppins cursor-pointer text-[14px] dark:text-white text-slate-200 mb-1`}
            >
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="matchCaseCheckbox"
                  checked={matchCase}
                  onChange={handleMatchCaseChange}
                  className="w-4 h-4 bg-orange-500 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="matchCaseCheckbox">{t("tool.Match_Case")}</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="matchWholeWordCheckbox"
                  checked={matchWholeWord}
                  onChange={handleMatchWholeWordChange}
                  className="w-4 h-4 bg-orange-500 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="matchWholeWordCheckbox">{t("tool.Match_Whole_Word")}</label>
              </div>
            </div>}
          {advancedOptions && <div className="flex flex-col sm:flex-row gap-5 justify-evenly xl:justify-around w-screen xl:px-0 sm:px-16 px-6">
            <div>
              <textarea
                rows='1'
                value={findText}
                onChange={handleFindTextChange}
                placeholder={t("tool.Text_to_Find")}
                cols='50'
                spellCheck="false"
                className='cursor-text resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              ></textarea>
            </div>
            <div>
              <textarea
                placeholder={t("tool.Text_to_Replace")}
                rows='1'
                spellCheck="false"
                value={replaceText}
                onChange={handleReplaceTextChange}
                cols='50'
                className=' cursor-text resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              ></textarea>
            </div>
          </div>
          }
        </div>
      </section>
    </>
  );
};

export default Toolarea;
