export const languageOptions = [
    { code: "en", name: "English", country_code: "us" },
    { code: "fr", name: "Français", country_code: "fr" },
    { code: "es", name: "Español", country_code: "es" },
    { code: "tr", name: "Türkçe", country_code: "tr" },
    { code: "de", name: "Deutsch", country_code: "de" },
    { code: "zh", name: "中文", country_code: "cn" }, 
    { code: "ru", name: "Русский", country_code: "ru" }, 
    { code: "pt", name: "Português", country_code: "pt" },
    { code: "ja", name: "日本語", country_code: "jp" }, 
    { code: "ar", name: "العربية", country_code: "sa" }, 
    { code: "hi", name: "हिन्दी", country_code: "in" }, 
    { code: "it", name: "Italiano", country_code: "it" }, 
    { code: "nl", name: "Nederlands", country_code: "nl" }, 
    { code: "ko", name: "한국어", country_code: "kr" }, 
    { code: "sv", name: "Svenska", country_code: "se" }, 
    { code: "pl", name: "Polski", country_code: "pl" }
];

export const supportedLanguages = ["en", "fr", "es", "tr", "de", "zh", "ru", "pt", "ja", "ar", "hi", "it", "nl", "ko", "sv", "pl"];
