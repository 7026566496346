import { useEffect, useState } from "react";
import { Navbar, Hero, Toolarea } from "./components";
import styles from "./styles";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "./utils/utils";

function App() {
  const { t } = useTranslation();
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "system");
  const element = document.documentElement;
  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const currentLanguage = i18next.language;
  const [advancedOptions, setAdvancedOptions] = useState(false);

  function onWindowMatch() {
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && darkQuery.matches)) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }
  onWindowMatch();

  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");
        break;
      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");
        break;
      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
    // eslint-disable-next-line
  }, [theme]);

  darkQuery.addEventListener("change", (e) => {
    if (!("theme" in localStorage)) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  });
  const siteName = t("sitenamemobile");
  const currentYear = new Date().getFullYear();

  const title = `${t("title")} - ${siteName} ${currentYear}`;
  return (
    <div className='dark:bg-primary min-h-screen w-full overflow-hidden text-black-100 bg-gray'>
      <Helmet>
        <html lang={currentLanguage} />
        <title>{title}</title>
        <meta name='description' content={t("description")} />
        <meta name='keywords' content={t("keywords")} />
        <link rel='canonical' href={`https://find-and-replace-text-online.leonineinfo.com/${currentLanguage}`} />
        {supportedLanguages.map((lang, index) => (
          <link key={index} rel='alternate' hreflang={lang} href={`https://find-and-replace-text-online.leonineinfo.com/${lang}`} />
        ))}
        <link rel='alternate' hreflang='x-default' href={`https://find-and-replace-text-online.leonineinfo.com/${currentLanguage}`} />
        <script type='application/ld+json'>{`
  {
    "@context": "http://schema.org",
    "@type": "WebApplication", 
    "name": "${t("title")}",
    "description": "${t("description")}",
    "keywords": "${t("keywords")}",
    "applicationCategory": "Utility",
    "url": "https://find-and-replace-text-online.leonineinfo.com/${currentLanguage}",
    "inLanguage": "${currentLanguage}",
    "author": {
      "@type": "Person",
      "name": "Darsh Dev"
    },
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://find-and-replace-text-online.leonineinfo.com/?q={search_term_string}",
        "inLanguage": "${currentLanguage}",
        "encodingType": "application/x-www-form-urlencoded"
      },
      "query-input": "required name=search_term_string"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5", 
      "reviewCount": "150"
    }
  }
`}</script>
      </Helmet>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar theme={theme} setTheme={setTheme} />
        </div>
      </div>
      <div className={`dark:bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero theme={theme} />
        </div>
      </div>
      <div className={`dark:bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Toolarea theme={theme} advancedOptions={advancedOptions} setAdvancedOptions={setAdvancedOptions} />
        </div>
      </div>
      <div className={`${advancedOptions ? "extra-visible" : "extra-notvisible"}`}></div>
    </div>
  );
}

export default App;
