import styles from "../styles.js";
import GetStarted from "./GetStarted";
import { TbDiscountCheck } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import languageToImage from "./languageImages.js";
import i18next from "i18next";
const Hero = ({ theme }) => {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const heroImage = languageToImage[currentLanguage];

  return (
    <section id='home' className={`flex md:flex-row flex-col mb-10`}>
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        <div className={`${styles.flexCenter} flex flex-row items-center py-[6px] px-4 
        ${theme === "light" ? "bg-slate-500 " : theme === "dark" ? "bg-discount-gradient " : "bg-slate-500 "}
        rounded-[10px] mb-2`}>
          <TbDiscountCheck className={` ${styles.flexCenter} w-[20px] h-[20px] object-contain dark:text-sky-400 text-green-500`} alt='right' />
          <p className={`${styles.paragraph} ml-2`}>
            <span className=' dark:text-white text-white'>{t("hero.Find")} </span>
            {t("hero.andreplace")} <span className=' dark:text-white text-white'>{t("hero.text")} </span> {t("hero.online")}
          </p>
        </div>

        <div className='flex flex-row justify-between items-center w-full'>
          <h2 className='flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-slate-800 dark:text-white ss:leading-[100.8px] leading-[75px]'>
            {t("hero.quick")}
            <br className='sm:block hidden' />{" "}
            <span
              className={` font-poppins
                ${theme === "light" ? "light-text-gradient" : theme === "dark" ? "text-gradient" : "light-text-gradient"}
                `}
            >
              {t("hero.onlinetext")}
            </span>{" "}
          </h2>
          <div className='ss:flex hidden md:mr-4 mr-0'>
            <GetStarted theme={theme} />
          </div>
        </div>
        <h2 className='font-poppins font-semibold ss:text-[68px] text-[52px] text-slate-800 dark:text-white ss:leading-[100.8px] leading-[75px] w-full'>
          {t("hero.replacer")}
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5 text-slate-800 dark:text-gray-300`}>{t("hero.para")}</p>
      </div>

      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        <img
          src={heroImage}
          alt={t('title')}
          className='w-[100%] h-[100%] relative z-[2]'
        />
        <div className='absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient' />
        <div className='absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40' />
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient' />
      </div>

      <div className={`ss:hidden ${styles.flexCenter}`}>
        <GetStarted theme={theme} />
      </div>
    </section>
  );
};

export default Hero;
