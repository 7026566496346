import image_en from "../assets/find-and-replace-text-online.png";
import image_fr from "../assets/Trouver-et-remplacer-du-texte-en-ligne-fr.png";
import image_es from "../assets/Buscar-y-reemplazar-texto-en-línea-es.png";
import image_tr from "../assets/Metin-online-bul-ve-değiştir-tr.png";
import image_de from "../assets/Finden-und-Ersetzen-von-Text-online-de.png";
import image_zh from "../assets/在线查找和替换文本-zh.png";
import image_ru from "../assets/Найти-и-заменить-текст-онлайн-ru.png";
import image_pt from "../assets/Encontrar-e-substituir-texto-online-pt.png";
import image_ja from "../assets/オンラインでテキストを検索および置換する-ja.png";
import image_ar from "../assets/البحث-واستبدال-النص-عبر-الإنترنت-ar.png";
import image_hi from "../assets/ऑनलाइन-टेक्स्ट-खोजें-और-बदलें-hi.png";
import image_it from "../assets/Trovare-e-sostituire-testo-online-it.png";
import image_nl from "../assets/Online-tekst-zoeken-en-vervangen-nl.png";
import image_ko from "../assets/온라인에서-텍스트-찾아-바꾸기-ko.png";
import image_sv from "../assets/Sök-och-ersätt-text-online-sv.png";
import image_pl from "../assets/Znajdź-i-zamień-tekst-online-pl.png";

const languageToImage = {
  en: image_en,
  fr: image_fr,
  es: image_es,
  tr: image_tr,
  de: image_de,
  zh: image_zh,
  ru: image_ru,
  pt: image_pt,
  ja: image_ja,
  ar: image_ar,
  hi: image_hi,
  it: image_it,
  nl: image_nl,
  ko: image_ko,
  sv: image_sv,
  pl: image_pl,
};

export default languageToImage;
