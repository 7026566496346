import styles from "../styles.js";
import { arrowUp } from "../assets";
import { useTranslation } from "react-i18next";

const GetStarted = ({ theme }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.flexCenter} w-[140px] h-[140px] rounded-full p-[2px] cursor-pointer
   ${theme === "light" ? "bg-green-gradient" : theme === "dark" ? "bg-blue-gradient" : "bg-green-gradient"}
  `}
    >
      <div className={`${styles.flexCenter} flex-col bg-slate-800 dark:bg-primary w-[100%] h-[100%] rounded-full`}>
        <div className={`${styles.flexStart} flex-row`}>
          <p className='font-poppins font-medium text-[18px] leading-[23.4px]'>
            <span
              className={` font-poppins
                ${theme === "light" ? "light-text-gradient" : theme === "dark" ? "text-gradient" : "light-text-gradient"}
                `}
            >
              {t("GetStarted.get")}
            </span>
          </p>
          <img src={arrowUp} alt='arrow-up' className='w-[23px] h-[23px] object-contain' width="23" height="23" />
        </div>

        <p className='font-poppins font-medium text-[18px] leading-[23.4px]'>
          <span
            className={` font-poppins
                ${theme === "light" ? "light-text-gradient" : theme === "dark" ? "text-gradient" : "light-text-gradient"}
                `}
          >
            {t("GetStarted.Started")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default GetStarted;
